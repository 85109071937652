import { theme } from '@rario/shared-components'
import { useEffect, useState } from 'react'

const useCountdown = (targetDate: Date) => {
  const countDownDate = new Date(targetDate).getTime()

  const [countDown, setCountDown] = useState(0)

  useEffect(() => {
    setCountDown(countDownDate - new Date().getTime())

    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime())
    }, 1000)

    return () => clearInterval(interval)
  }, [countDownDate])

  return getReturnValues(countDown)
}

const getReturnValues = (countDown: number) => {
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24))
  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000)

  let timerVariant = {
    color: theme.colors.primary,
    borderVariant: theme.colors.secondary,
    backgroundVariant: 'rgba(217, 217, 217, 0.02)',
    borderColorVariant:
      'linear-gradient(90deg, rgba(205, 65, 58, 0) 0%, #CD413A 15.63%, #CD413A 80.73%, rgba(205, 65, 58, 0) 100%)',
  }
  let endTime: string = ''
  let isSaleEnded: boolean = false
  if (days > 0) {
    endTime = `${days}d`
    isSaleEnded = false
    timerVariant = {
      color: theme.colors.primary,
      borderVariant: theme.colors.white,
      backgroundVariant: '#1D2228',
      borderColorVariant:
        'linear-gradient(90deg, rgba(205, 65, 58, 0) 0%, #515151 15.63%, #515151 80.73%, rgba(205, 65, 58, 0) 100%)',
    }
  } else if (days === 0 && hours >= 1) {
    endTime = `${hours}hr : ${minutes}m : ${seconds}s`
    isSaleEnded = false
    timerVariant = {
      color: theme.colors.atomicTangerine,
      borderVariant: theme.colors.white,
      backgroundVariant: '#1D2228',
      borderColorVariant:
        'linear-gradient(90deg, rgba(205, 65, 58, 0) 0%, #515151 15.63%, #515151 80.73%, rgba(205, 65, 58, 0) 100%)',
    }
  } else if (days === 0 && hours < 1 && minutes >= 5) {
    endTime = `${minutes}m : ${seconds}s`
    isSaleEnded = false
    timerVariant = {
      color: theme.colors.secondary,
      borderVariant: minutes >= 5 && minutes < 29 ? theme.colors.maize : theme.colors.white,
      backgroundVariant: '#1D2228',
      borderColorVariant:
        'linear-gradient(90deg, rgba(205, 65, 58, 0) 0%, #CD413A 15.63%, #CD413A 80.73%, rgba(205, 65, 58, 0) 100%)',
    }
  } else if (days === 0 && hours < 1 && minutes < 5) {
    endTime = minutes < 1 ? `${seconds}s` : `${minutes}m : ${seconds}s`
    isSaleEnded = false
    timerVariant = {
      color: theme.colors.secondary,
      borderVariant: theme.colors.white,
      backgroundVariant: 'rgba(153, 48, 41, 1)',
      borderColorVariant:
        'linear-gradient(90deg, rgba(205, 65, 58, 0) 0%, #CD413A 15.63%, #CD413A 80.73%, rgba(205, 65, 58, 0) 100%)',
    }
  } else {
    endTime = '0'
    isSaleEnded = true
    timerVariant = {
      color: theme.colors.secondary,
      borderVariant: theme.colors.primary,
      backgroundVariant: '#686B70',
      borderColorVariant:
        'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 15.63%, #FFFFFF 80.73%, rgba(255, 255, 255, 0) 100%)',
    }
  }
  return { timeLeft: endTime, timeColorVariant: timerVariant, isSaleEnded }
}

export { useCountdown }
