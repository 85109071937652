import { theme } from '@rario/shared-components'
import { ReactNode } from 'react'
import { BackdropFilterFlex } from './BottomButtonWrapper.styles'

const BottomButtonWrapper: React.FunctionComponent<{
  children: ReactNode
  isStyleActive?: boolean
}> = ({ children, isStyleActive = true }) => {
  return (
    <>
      {isStyleActive ? (
        <BackdropFilterFlex
          filterBlur="20px"
          maxWidth={['100%', `${theme.maxDesktopLayoutWidth}px`]}
          position={'fixed'}
          bottom={0}
          left={'50%'}
          marginLeft={[`calc(100% / -2)`, `calc(min(100%,${theme.maxDesktopLayoutWidth}px) / -2)`]}
          zIndex={9999}
          background="rgba(255, 255, 255, 0.04)"
          width="100%"
          height="fit-content"
          justifyContent="space-around"
          alignItems="center"
          p="16px"
          borderTop={`1px solid ${theme.colors.whites[3]}`}
        >
          {children}
        </BackdropFilterFlex>
      ) : (
        children
      )}
    </>
  )
}
export default BottomButtonWrapper
