const RarioInitialIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
}> = ({ className = '', width = '11', height = '9' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 11 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.36148 4.88274L9.43091 2.25338L7.6574 0L0.941658 0L2.71517 2.25338L6.77065 2.25338L4.70121 4.88274L7.39809 8.30932H10.0584L7.36148 4.88274Z"
      fill="#FF4F44"
    />
  </svg>
)

export default RarioInitialIcon
