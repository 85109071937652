import { trackEvent, FlexBox, Button } from '@rario/shared-components'
import { IApiException } from 'api/Api.exception'
import { createUserOrder } from 'api/Order.api'
import { emitCustomEvent } from 'components/CustomEvents'
import { ButtonTextEnum, NFTButtonStateProps, PaymentKycStateEnum } from 'interfaces'
import { useRouter } from 'next/router'
import { useContext, useEffect, useRef, useState } from 'react'
import { useCountdown } from 'hooks/useCountdown'
import { isFutureDate, isUsernameValid } from 'utils/utils'
import { NFTPicksButtonProps } from 'interfaces/picks'
import usePageControl from 'hooks/usePageControl'
import { AppConfigContext } from 'contexts/AppConfigContext'
import { useCustomUser } from 'contexts/CustomUserProvider'
import { ModalContext } from 'components/ModalManager/ModalProvider'
import { useCookies } from 'react-cookie'
import { UTM_COOKIES } from 'constant'
import BottomButtonWrapper from 'components/BottomButtonWrapper/BottomButtonWrapper'
// import DiscountPriceDescription from 'components/PackDetail/DiscountPriceDescription'
import { useCustomAuth } from 'contexts/CustomAuthProvider'
import { trackApiErrorResponse } from 'utils/trackEventUtils'

const NFTPicksButton: React.FunctionComponent<NFTPicksButtonProps> = ({
  catalogDoc,
  // walletBalance,
}) => {
  const {
    userProfileModal,
    setUserProfileModal,
    setPaymentSummaryModal,
    setPhoneVerificationModal,
  } = useContext(ModalContext)
  const { accessToken } = useCustomAuth()
  const { user } = useCustomUser()
  const appConfig = useContext(AppConfigContext)
  const { isIndianIP } = appConfig
  const router = useRouter()
  const { asPath, push, query } = router
  const { referer } = query
  const [loading, setLoading] = useState(true)
  const [buttonState, setButtonState] = useState<NFTButtonStateProps>({
    buy: false,
    buttonText: ButtonTextEnum.LOGIN_AND_BUY.toUpperCase(),
    disabled: true,
    showTooltip: false,
  })
  const userDetailsExists = useRef<boolean>(false)
  const userDetailsModalTriggered = useRef<boolean>(false)
  const { isHomeLandingPage, isPickPlp, isPickPdp } = usePageControl()
  const isHomePageOrPicksPage = isHomeLandingPage || isPickPlp
  const picksButtonCTAText = isHomePageOrPicksPage
    ? ButtonTextEnum.BUY
    : !user
    ? ButtonTextEnum.LOGIN_AND_BUY
    : ButtonTextEnum.BUY_NOW

  const startTime = catalogDoc?.campaign_start_ts
  const endTime = catalogDoc?.campaign_end_ts

  const isUpcomingSale = isFutureDate(new Date(Number(startTime) * 1000))
  const saleEnded = useCountdown(new Date(Number(endTime) * 1000)).isSaleEnded

  const [cookies] = useCookies([
    UTM_COOKIES.UTM_SOURCE,
    UTM_COOKIES.UTM_CAMPAIGN,
    UTM_COOKIES.UTM_CONTENT,
    UTM_COOKIES.UTM_MEDIUM,
  ])

  useEffect(() => {
    handleButtonState()
    userDetailsExists.current = isUsernameValid(user?.profile?.firstName, user?.profile?.lastName)
  }, [user, saleEnded, catalogDoc])

  useEffect(() => {
    if (userProfileModal?.isSuccess && userDetailsModalTriggered.current) {
      userDetailsExists.current = true
      userDetailsModalTriggered.current = false
      router.replace(router.asPath)
      initiatePurchase()
    }
  }, [userProfileModal])

  const isAvailableForSale = catalogDoc?.num_of_usage

  const initiatePurchase = async () => {
    if (!user?.isPhoneVerified) {
      setPhoneVerificationModal({ canSkip: false })
      return
    }
    if (user?.kycStatus !== PaymentKycStateEnum.SUCCESS) {
      router.push('/kyc')
      return
    }

    if (!userDetailsExists.current) {
      userDetailsModalTriggered.current = true
      setUserProfileModal({ show: true })
      return
    }

    try {
      setLoading(true)
      const { data } = await createUserOrder(
        {
          catalogId: catalogDoc.asset_id as string,
          isIndianIP: isIndianIP as boolean,
          utmSource: cookies?.[UTM_COOKIES.UTM_SOURCE]?.toString(),
          utmCampaign: cookies?.[UTM_COOKIES.UTM_CAMPAIGN]?.toString(),
          utmContent: cookies?.[UTM_COOKIES.UTM_CONTENT]?.toString(),
          utmMedium: cookies?.[UTM_COOKIES.UTM_MEDIUM]?.toString(),
        },
        accessToken
      )
      setPaymentSummaryModal({ orderId: data.orderId })
      setLoading(false)
    } catch (e) {
      setLoading(false)
      const error = e as IApiException
      const errorResponse = error.response ? error.response : { message: error.message }
      emitCustomEvent('errorCodes', errorResponse)
      trackApiErrorResponse(error, {
        subCategory: 'picks-create_order_api_response_error',
        category: 'picks',
      })
    }
  }
  const nftEventData = {
    card_id: catalogDoc?.asset_id,
    card_tier: catalogDoc?.attributes?.scarcity,
    total_circulation: catalogDoc?.total_serial_number,
    card_name: catalogDoc?.name,
    listing_price: catalogDoc?.sale_price,
    nft_type: 'picks',
  }

  const clickCTATrackEvent = (eventName: string, ctaText: string) => {
    trackEvent('Section Interacted', 'click', {
      sub_category: 'listings-picks',
      type: 'page',
      category: 'picks',
      view_type: 'constrained',
      ...nftEventData,
      click_type: 'button',
      click_text: ctaText.toUpperCase(),
      click_action: eventName,
    })
  }

  const handleButtonState = () => {
    let newButtonState = buttonState

    if (!user) {
      setLoading(false)
      setButtonState({
        buy: false,
        buttonText: picksButtonCTAText,
        disabled: false,
        showTooltip: false,
        onClick: () => {
          clickCTATrackEvent('click_cta_listing_details', ButtonTextEnum.LOGIN_AND_BUY)
          setButtonState({
            buy: false,
            buttonText: picksButtonCTAText,
            disabled: false,
            showTooltip: false,
          })
          referer
            ? push(`/login?redirect_uri=${asPath}`)
            : push(
                `/login?redirect_uri=${
                  asPath.endsWith('?') ? asPath.slice(0, -1) : asPath
                }&referer=home`
              )
        },
      })
      return
    } else if (saleEnded || !catalogDoc?.on_sale) {
      newButtonState = {
        buy: false,
        buttonText: picksButtonCTAText,
        disabled: true,
        showTooltip: false,
        onClick: () => {},
      }
    } else if (isUpcomingSale) {
      newButtonState = {
        buy: false,
        buttonText: picksButtonCTAText,
        disabled: true,
        showTooltip: false,
        onClick: () => {},
      }
    } else if (!isAvailableForSale) {
      newButtonState = {
        buy: false,
        buttonText: picksButtonCTAText,
        disabled: true,
        showTooltip: false,
        tooltipText: '',
        onClick: () => {},
      }
    } else {
      newButtonState = {
        buy: true,
        buttonText: picksButtonCTAText,
        disabled: false,
        showTooltip: false,
        onClick: () => {
          clickCTATrackEvent('click_cta_listing_details', ButtonTextEnum.BUY)

          initiatePurchase()
        },
      }
    }
    setButtonState(newButtonState)
    setLoading(false)
  }

  // const marketingWalletBalance = walletBalance?.marketingWalletBalance
  return (
    <>
      <FlexBox
        width={'fit-content'}
        position={'relative'}
        alignItems={['flex-start', null, null, 'center']}
        flexDirection={[buttonState.showTooltip ? 'row' : 'column', null, null, 'row']}
      >
        <BottomButtonWrapper isStyleActive={isPickPdp}>
          <FlexBox flexDirection={'column'} width="100%">
            {/* {isPickPdp &&
            catalogDoc?.sale_price &&
            marketingWalletBalance &&
            marketingWalletBalance > 0 ? (
              <FlexBox pb="16px">
                <DiscountPriceDescription
                  salePrice={catalogDoc?.sale_price}
                  marketingWalletBalance={walletBalance?.marketingWalletBalance}
                />
              </FlexBox>
            ) : (
              <></>
            )} */}
            <Button
              variant={'dynamic'}
              name={buttonState.buttonText.toUpperCase()}
              disabled={buttonState.disabled}
              loading={loading}
              onClick={buttonState.onClick}
              width={isPickPdp ? '100%' : 'fit-content'}
              textTransform={'uppercase'}
              colorVariant={isPickPdp ? 'whiteFilled' : 'whiteOutline'}
              fontSize={isPickPdp ? '14px' : '12px'}
            />
          </FlexBox>
        </BottomButtonWrapper>
      </FlexBox>
    </>
  )
}

export default NFTPicksButton
