import { customStyled, FlexBox, ThemeType } from '@rario/shared-components'
import { getCDNUrl } from 'utils/utils'

export const SoldTagContainer = customStyled(FlexBox)<{
  isPick: boolean
  isListing: boolean
  isHomeLandingPage?: boolean
  theme?: ThemeType
}>`
      cursor: default;
      position: absolute;
      width: ${({ isListing }) => (isListing ? '24px' : '20px')};;
      background-image: ${`url(${getCDNUrl('/images/picks/sold-by-rario-tag-bg.png')})`};
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      transform: scaleY(-1);
      z-index: 99;
      transform: rotate(360deg) scaleX(1);
      height: 90px;
      top: ${({ isListing }) => (isListing ? '90px' : '40px')};
      right: ${({ isListing, isHomeLandingPage }) =>
        isListing ? '40px' : isHomeLandingPage ? '10px' : '8px'};
      & .innerFlexBox{
        position: relative;
        top: 33px;
        left: ${({ isListing }) => (isListing ? '-20px' : '-22px')};
        transform: rotate(-90deg) scaleX(1);
      }
      @media(max-width: ${({ theme }) => theme.breakpoints[1]}) {
        height: 90px;
        top: ${({ isListing }) => (isListing ? '90px' : '40px')};
        right: ${({ isListing, isHomeLandingPage }) =>
          isListing ? '40px' : isHomeLandingPage ? '10px' : '8px'};
      }
      @media(max-width: 375x) {
        right: ${({ isListing }) => (isListing ? '40px' : '0px !important')};
      }
      @media(max-width: 370px) {
        right: ${({ isListing, isHomeLandingPage }) =>
          isListing ? '40px' : isHomeLandingPage ? '-3px' : '1px'};
      }
`

export const SoldTagContainerTextWrapper = customStyled.div<{
  theme?: ThemeType
  fontWeight: number
  color: string
  margin: string
}>`
      font-size: 11px;
      line-height: 11px;
      font-weight:  ${(props) => props.fontWeight};
      margin-left: ${(props) => props.margin};
      color: ${(props) => props.color};
`
