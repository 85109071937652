import { FlexBox, TextField, theme } from '@rario/shared-components'
import RarioInitialIcon from 'assets/svg/RarioInitialIcon'
import { SoldTagContainer } from './SoldByRarioTag.styles'
import usePageControl from 'hooks/usePageControl'

const SoldByRarioTag: React.FunctionComponent<{ isListing: boolean }> = ({ isListing }) => {
  const { isHomeLandingPage } = usePageControl()
  return (
    <SoldTagContainer
      onClick={(e) => e.preventDefault()}
      isListing={isListing}
      isPick={true}
      isHomeLandingPage={isHomeLandingPage}
    >
      <FlexBox height={'20px'} className="innerFlexBox">
        <TextField
          fontWeight={400}
          fontSize={'9px'}
          lineHeight={'9px'}
          color={theme.colors.whites[8]}
          width={'35px'}
        >
          Sold By
        </TextField>
        <TextField
          fontWeight={600}
          fontSize={'9px'}
          lineHeight={'9px'}
          color={theme.colors.white}
          width={'40px'}
          position={'relative'}
          top={'-1px'}
        >
          RARIO
          <RarioInitialIcon />
        </TextField>
      </FlexBox>
    </SoldTagContainer>
  )
}

export default SoldByRarioTag
